import React from 'react';
import SignupForm from '../components/SignupForm';
import trailCollectivLogo from '../assets/images/TrailCollectivStackLogoVersion2.png';
import saskParksLogo from '../assets/images/SaskParksLogo.png';
import tourismSkLogo from '../assets/images/TourismSkLogo.png';
import '../App.css';

export default function App() {
  return (
    <div className='vh-100 w-100 flex flex-column justify-center items-center tc' >
      <div className='mb3'>
        <img
          src={trailCollectivLogo}
          className='w4 h-auto'
          alt="TrailCollectiv"
        />
      </div>

      <div className="center justify-center">
        <h1 
          // className='f1 mv3-ns'
          className='h-auto w-auto mv3-ns'
          style={{ color: '#0B3422' }}
        > 

          TrailCollectiv <br/> Subscription
        </h1>

        <p className='white fw6 lh-copy mt2 tl center mw6 normal '
          style={{ color: '#0B3422' }}
        >
          TrailCollectiv hiking app for families has partnered
          <br/>with Sask Parks and Tourism Saskatchewan to give
          <br/>annual park pass holders FREE premium access! 
          <br/>Click the button below to access the TrailCollectiv
          <br/>subscription.
        </p>

        <SignupForm />
      </div>

      <div className='mb3'>
        <img
          src={tourismSkLogo}
          className='w4 h-auto ph2'
          alt="TrailCollectiv"
        />

        <img
          src={saskParksLogo}
          className='w4 h-auto ph2'
          alt="Sask Parks"
        />
      </div>
    </div>
  );
}
