const validateEmail = (email) => {
    
    // best pattern I could find that 99.99% works from source: https://www.w3resource.com/javascript/form/email-validation.php

    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@.]+$/;    // fails with test@test..com
    // Regex explanation:
    // ^: Start anchor
    // [^\s@]: matches one or more characters that are not whitespace (\s) or "@" symbol
    // @: matches the "@" symbol.
    // [^\s@]: matches one or more characters that are not whitespace (\s) or "@" symbol
    // \.: matches the "." symbol (Note: it's escaped with a backslash because "." has special meaning in regex)
    // [^\s@.]: matches one or more characters that are not whitespace (\s) or "@" symbol or "."
    // $: End anchor
    
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
};

export default validateEmail;
