import axiosConfig from "./axiosConfig";

const baseURL = process.env.REACT_APP_API_BASE_URL;

export async function giveSubscription(postData) {
    try {
        console.log(postData);

        const response = axiosConfig.patch(baseURL + `/user/saskparks/givesubscription`, postData);

        return response;
    } catch (error) {
        console.error("Error giving subscription:", error);
        throw error;
    }
}