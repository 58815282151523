import React, { useState } from 'react';
import validateEmail from '../utils/validateEmail';
import { giveSubscription } from '../services/endpoints';

const SignupForm = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const onSignUp = async (e) => {
        e.preventDefault();

        if (!email) {
            setError('Email cannot be empty');
            return;
        } else if (!validateEmail(email)) {
            setError('Please enter a valid email address');
            return;
        }

        setError('');
        await signUpRequest();
    };

    const signUpRequest = async () => {
        const postData = {
            recipientFirstName: firstName,
            recipientLastName: lastName,
            recipientEmail: email
        };

        const response = await giveSubscription(postData);

        if (response.status !== 200) {
            alert('Error!');
            return;
        }

        if (response.data.userExists) {
            setSuccessMessage(
                'Check your inbox and spam for steps to\nactivate the free subscription.'
            );
        } else {
            setSuccessMessage(
                'Check your inbox and spam for steps to\nactivate your free premium subscription.'
            );
        }

        setFirstName('');
        setEmail('');
    };

    return (
        <>
            <div className='flex justify-center'>
                {successMessage ? (
                    <div className='red b mw-100 tl'>
                        {successMessage.split('\n').map((line, index) => (
                            <p key={index}>{line}</p>
                        ))}
                    </div>
                ) : (
                    <form className='tc mw-100'>
                        <div className='pa2'>
                            <input
                                className='pa2 lh-copy input-reset ba b--transparent hover-bg-washed-yellow grow w5 br3'
                                name='firstName'
                                label='firstName'
                                onChange={(e) => setFirstName(e.target.value)}
                                type='text'
                                value={firstName}
                                placeholder='First Name'
                            />
                        </div>

                        {/* <div className='pa2'>
                            <input
                                className='pa2 lh-copy input-reset ba b--transparent hover-bg-washed-yellow grow w5 br3''
                        name="lastName"
                        label="lastName"
                        onChange={e => setLastName(e.target.value)}
                        type="text"
                        value={lastName}
                        placeholder="Last name"
                    />
                </div> */}

                        <div className='red b w-100'>{error}</div>

                        <div className='pa2'>
                            <input
                                className='pa2 lh-copy input-reset ba b--transparent hover-bg-washed-yellow grow w5 br3'
                                name='email'
                                label='Email'
                                onChange={(e) => setEmail(e.target.value)}
                                type='email'
                                value={email}
                                placeholder='Email Address'
                                required
                            />
                        </div>

                        <div className='pa2 mb4 relative w5 center grow'>
                            <button
                                type='submit'
                                onClick={onSignUp}
                                className='pa3 w-100 white tl fw9 b--transparent relative z-2 pointer input-reset'
                                style={{ backgroundColor: '#18708E' }}
                            >
                                Get your FREE Premium
                                <br />
                                Subscription →
                            </button>

                            <div
                                className='absolute top-1 left-1 z-1 pointer'
                                style={{
                                    width: 'calc(100% - 5px)',
                                    height: 'calc(100% - 10px)',
                                    backgroundColor: '#0B3422'
                                }}
                            ></div>
                        </div>
                    </form>
                )}
            </div>
        </>
    );
};

export default SignupForm;